/* eslint-disable import/prefer-default-export */
import { signer, signerResponse, recovery, passwordRecovery } from 'interfaces/Signer';
import api, { apiRecovery } from 'services';

export const postSignin = async (payload: signer): Promise<signerResponse> => {
  const response = await api.post<signerResponse>('/signin', payload);
  api.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
  return response.data;
};

export const recoverPassword = async (payload: passwordRecovery) => {
  const response = await apiRecovery.put('/password', payload);
  return response.data;
};

export const sendEmailRecover = async (payload: recovery) => {
  const response = await apiRecovery.post('/recovery', payload);
  return response.data;
};
