import NotificationBadge from 'components/NotificationBadge';
import { useAppDispatch, useAppSelector } from 'hooks/useAppStore';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { clearSignin } from 'store/ducks/Signer';

import { AccountCircle, Logout, Person, Article } from '@mui/icons-material';
import { Box, Grid, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';

const MenuOptions = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => {
    return state.signer.signin;
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    dispatch(clearSignin());
    handleClose();
    navigate('/signin');
  };

  const handleDownloadDoc = async () => {
    if (!process.env.REACT_APP_VILEVEPAY_DOC) {
      return;
    }

    const link = document.createElement('a');

    link.href = process.env.REACT_APP_VILEVEPAY_DOC;
    link.id = 'auxDownload';
    link.setAttribute('download', 'documentação vilevepay.docx');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <Box sx={{ display: { md: 'flex' } }}>
      <Grid container spacing={2}>
        <Grid item>
          <NotificationBadge />
        </Grid>
        <Grid item>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            // aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle fontSize="medium" />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem>
              <ListItemIcon>
                <Person fontSize="small" color="primary" />
              </ListItemIcon>
              {user}
            </MenuItem>
            <MenuItem onClick={handleDownloadDoc}>
              <ListItemIcon>
                <Article fontSize="small" color="primary" />
              </ListItemIcon>
              Documentação
            </MenuItem>
            <MenuItem onClick={handleLogOut}>
              <ListItemIcon>
                <Logout fontSize="small" color="primary" />
              </ListItemIcon>
              Sair
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MenuOptions;
