/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import { hierarchy } from 'enums/hierarchy';
import { assignees, treeHierarchyDocument, allChainDocument, queryAllChain } from 'interfaces/Assignee';
import { updateResponseDb } from 'interfaces/defaultResponse';
import {
  paramsQueryAdvanced,
  payloadInsertHierarchy,
  unlinkHierarchyParms,
  resposeHierarchyCount,
  countHierarchiesParams,
} from 'interfaces/Hierarchy';
import api from 'services';

export const getTreeHierarchy = async (payload?: queryAllChain): Promise<treeHierarchyDocument> => {
  const response = await api.get<treeHierarchyDocument>(
    `${hierarchy.service}/treeHierarchy?cpf_cnpj=${payload?.cpf_cnpj}&tipo_cessionario=${payload?.tipo_cessionario}`,
  );
  return response.data;
};

export const getAllChain = async (payload?: queryAllChain): Promise<allChainDocument> => {
  const response = await api.get<allChainDocument>(`${hierarchy.service}/allChain`, {
    params: {
      cpf_cnpj: payload?.cpf_cnpj,
      tipo_cessionario: payload?.tipo_cessionario,
    },
  });
  return response.data;
};

export const getValidHierarchyList = async (params: paramsQueryAdvanced): Promise<assignees> => {
  const response = await api.get<assignees>(`${hierarchy.service}/validList`, { params });
  return response.data;
};

export const putValidHierarchyList = async (params?: payloadInsertHierarchy): Promise<assignees> => {
  const response = await api.put<assignees>(`${hierarchy.service}/setProfileHierarchy`, params);
  return response.data;
};

export const putUnlinkHierarchy = async (body: unlinkHierarchyParms): Promise<updateResponseDb> => {
  const response = await api.put<updateResponseDb>(`${hierarchy.service}/unlinkProfileHierarchy`, body);
  return response.data;
};

export const getCountHierarchies = async (params: countHierarchiesParams): Promise<resposeHierarchyCount> => {
  const response = await api.get<resposeHierarchyCount>(`${hierarchy.service}/count`, { params });
  return response.data;
};
