/* eslint-disable no-param-reassign */
import { AxiosError } from 'axios';
import { equalizerInfoResponse, postEqualizationPayload } from 'interfaces/Pipeline';
import { reduxTemplate } from 'interfaces/redux';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getEqualizationInfo, postEqualization } from './service';

export const postIntoEqualizer = createAsyncThunk<
  equalizerInfoResponse,
  postEqualizationPayload,
  { rejectValue: reduxTemplate }
>('pipeline/equalization/post', async (payload, { rejectWithValue }) => {
  try {
    const data = await postEqualization(payload);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

export const getInfo = createAsyncThunk<equalizerInfoResponse, void, { rejectValue: reduxTemplate }>(
  'pipeline/equalization/info',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getEqualizationInfo();
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

interface IEntry extends reduxTemplate {
  info: equalizerInfoResponse;
}

const initialState: IEntry = {
  status: 'idle',
  message: '',
  info: {
    status: '',
    message: '',
    document: [],
    data: {
      isRunning: false,
      maxProcesses: 0,
      processing: null,
      processes: [],
    },
  },
};

export const equalizerReducer = createSlice({
  name: 'EntryStatus',
  initialState,
  reducers: {
    clearEqualizerStatus: (state) => {
      state.status = 'idle';
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postIntoEqualizer.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(postIntoEqualizer.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.info = action.payload;
      })
      .addCase(postIntoEqualizer.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(getInfo.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getInfo.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.info = action.payload;
      })
      .addCase(getInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      });
  },
});
export const { clearEqualizerStatus } = equalizerReducer.actions;
export default equalizerReducer.reducer;
