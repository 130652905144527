import React from 'react';
import { useNavigate } from 'react-router-dom';
import theme from 'styles/theme/dark';

import { ArrowBack, ReplayOutlined } from '@mui/icons-material';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';

interface Props {
  message?: string;
  error?: Error;
  resetBoundary: () => void;
}

const defaultError = new Error('Infelizmente não pudemos identificar o erro');

const ErrorFallback = ({ message, error, resetBoundary }: Props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const gap = isMobile ? '10px' : '60px';
  const fontSizes = {
    message: isMobile ? '1.5rem' : '2rem',
    details: isMobile ? '1rem' : '1.2rem',
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      minWidth="200px"
      minHeight="500px"
      width="100%"
      height="100%"
      gap={gap}
      justifyContent="center"
      alignItems="center"
    >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="80%">
        <Typography component="h1" fontSize={fontSizes.message}>
          {message}
        </Typography>
        <Typography component="pre" fontSize={fontSizes.details}>
          {error?.message}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-around" width="80%">
        <Button endIcon={<ReplayOutlined />} onClick={resetBoundary}>
          Tentar denovo
        </Button>
        <Button endIcon={<ArrowBack />} onClick={goBack}>
          Voltar
        </Button>
      </Box>
    </Box>
  );
};

ErrorFallback.defaultProps = {
  message: 'OOPS! Houve um erro',
  error: defaultError,
};

export default ErrorFallback;
