/* eslint-disable no-param-reassign */

import { AxiosError } from 'axios';
import { reduxTemplate } from 'interfaces/redux';
import {
  ChainReportParams,
  reportedChainResponse,
  transactionsReportParams,
  transactionsReportResponse,
  commissioningByDistResponse,
  commissioningByDistParams,
  dcTransactionsReportParms,
  distributorsGeneralReportParms,
} from 'interfaces/Reports';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getChainCommissioningByDist,
  getMetricsChainReport,
  getTransactionsReport,
  getDCtransactionsReport,
  getDistributorsGeneralReport,
} from './service';

export const chainReport = createAsyncThunk<reportedChainResponse, ChainReportParams, { rejectValue: reduxTemplate }>(
  'metrics/ChainReport',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await getMetricsChainReport(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const dcTransactionsReport = createAsyncThunk<{}, dcTransactionsReportParms, { rejectValue: reduxTemplate }>(
  'metrics/ChainReport',
  async (parms, { rejectWithValue }) => {
    try {
      await getDCtransactionsReport(parms);
      return {};
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const chainCommissioningByDist = createAsyncThunk<
  commissioningByDistResponse,
  commissioningByDistParams,
  { rejectValue: reduxTemplate }
>('metrics/chainCommissioningByDist', async (payload, { rejectWithValue }) => {
  try {
    const data = await getChainCommissioningByDist(payload);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

export const transactionsReport = createAsyncThunk<void, transactionsReportParams, { rejectValue: reduxTemplate }>(
  'metrics/transactionsReport',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await getTransactionsReport(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const distributorsGeneralReport = createAsyncThunk<
  void,
  distributorsGeneralReportParms,
  { rejectValue: reduxTemplate }
>('metrics/distributorsGeneralReport', async (params, { rejectWithValue }) => {
  try {
    const data = await getDistributorsGeneralReport(params);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

interface IReportedChainResponseReducer extends reduxTemplate {
  reportedChainResponse: reportedChainResponse;
  transactionsReportResponse: transactionsReportResponse;
}

const initialState: IReportedChainResponseReducer = {
  status: 'idle',
  message: '',
  reportedChainResponse: {
    message: '',
    status: '',
    data: [
      {
        ECDoc: '',
        EC: '',
        ECSum: 0,
        SCM: '',
        SCMSum: 0,
        IC: '',
        ICSum: 0,
        DC: '',
        DCSum: 0,
        RC1: '',
        RC1Sum: 0,
        RC2: '',
        RC2Sum: 0,
      },
    ],
  },
  transactionsReportResponse: {
    message: '',
    status: '',
    data: [
      {
        EC: '',
        nsu: '',
        numero_aparelho: '',
        valor_de_comissao: 0,
        quantia: 0,
        SCM: '',
        SCMVal: 0,
        IC: '',
        ICVal: 0,
        DC: '',
        DCVal: 0,
        RC1: '',
        RC1Val: 0,
        RC2: '',
        RC2Val: 0,
      },
    ],
  },
};

export const reportedChainReducer = createSlice({
  name: 'commission',
  initialState,
  reducers: {},
  // extraReducers: (builder) => {
  //   builder

  // },
});

export default reportedChainReducer.reducer;
