/* eslint-disable no-param-reassign */

import { AxiosError } from 'axios';
import { reduxTemplate } from 'interfaces/redux';
import { invoicingQuery, invoicingResponse } from 'interfaces/Reports';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getMetricsInvoicing } from './service';

export const invoicing = createAsyncThunk<
  invoicingResponse,
  invoicingQuery | undefined,
  { rejectValue: reduxTemplate }
>('mestrics/invoicing', async (payload, { rejectWithValue }) => {
  try {
    const data = await getMetricsInvoicing(payload);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

interface IInvoicingReducer extends reduxTemplate {
  invoicing: invoicingResponse;
}

const initialState: IInvoicingReducer = {
  status: 'idle',
  message: '',
  invoicing: {
    message: '',
    status: '',
    data: {
      commissionedValue: {
        EC: 0,
        SCM: 0,
        IC: 0,
        DC: 0,
        RC1: 0,
        RC2: 0,
        total: 0,
      },
      invoicing: 0,
      ranking: [],
    },
  },
};

export const invoicingReducer = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(invoicing.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(invoicing.fulfilled, (state, action) => {
        state.status = 'completed';
        state.invoicing = action.payload;
      })
      .addCase(invoicing.rejected, (state) => {
        state.status = 'failed';
        state.message = state.invoicing.message;
      });
  },
});

export default invoicingReducer.reducer;
