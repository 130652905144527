/* eslint-disable no-console */
import CustomMenu from 'components/CustomMenu';
import { useAppDispatch, useAppSelector } from 'hooks/useAppStore';
import { notifications } from 'interfaces/Notifications';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { notificationsList } from 'store/ducks/Notifications';

import { Notifications } from '@mui/icons-material';
import { Grid, Typography, Button, Divider, MenuList } from '@mui/material';
import Badge, { BadgeProps } from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => {
  return {
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  };
});

const NotificationBadge = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { list } = useAppSelector((state) => {
    return state.notifications;
  });
  useEffect(() => {
    dispatch(notificationsList());
    setInterval(() => {
      dispatch(notificationsList());
    }, 300000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [countNotification, setCountNotification] = useState<number>(0);
  const [dataNotifications, setDataNotifications] = useState<notifications | undefined>();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (router: string) => {
    handleClose();
    navigate(router);
  };

  useEffect(() => {
    if (list.document) {
      setDataNotifications(list);
      setCountNotification(list.document.length);
    }
  }, [list]);

  return (
    <Grid container>
      <Grid item>
        <IconButton
          size="large"
          edge="end"
          onClick={handleMenu}
          aria-label="account of current user"
          aria-haspopup="true"
          color="inherit"
        >
          <StyledBadge badgeContent={countNotification} color="success">
            <Notifications fontSize="medium" />
          </StyledBadge>
        </IconButton>
      </Grid>
      <Grid item>
        <CustomMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuList>
            <Grid container direction="column">
              {dataNotifications &&
                dataNotifications.document.map((data) => {
                  return (
                    <span key={data.url}>
                      <Grid item>
                        <Button
                          onClick={() => {
                            if (data.url) {
                              handleNavigate(data.url);
                            }
                          }}
                        >
                          <Grid container direction="column" alignItems="flex-start">
                            <Grid item>
                              <Typography
                                variant="subtitle2"
                                component="div"
                                fontWeight="700"
                                gutterBottom
                                color="primary"
                              >
                                {data.titulo}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body2">{data.descricao}</Typography>
                            </Grid>
                          </Grid>
                        </Button>
                      </Grid>
                      <Divider />
                    </span>
                  );
                })}
            </Grid>
          </MenuList>
        </CustomMenu>
      </Grid>
    </Grid>
  );
};

export default NotificationBadge;
