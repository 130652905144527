/* eslint-disable no-param-reassign */
import { AxiosError } from 'axios';
import { bodyChainCommission } from 'interfaces/Commission';
import { defaultResponse } from 'interfaces/defaultResponse';
import { reduxTemplate } from 'interfaces/redux';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { postCommissionChain } from './service';

export const postCommissionChainAllFees = createAsyncThunk<
  defaultResponse,
  bodyChainCommission[],
  { rejectValue: reduxTemplate }
>('hierarchy/setProfileHierarchy?params', async (params, { rejectWithValue }) => {
  try {
    const data: defaultResponse = await postCommissionChain(params);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

interface ICommission extends reduxTemplate {
  commissionChainAllFees: defaultResponse;
}

const initialState: ICommission = {
  status: 'idle',
  message: '',
  commissionChainAllFees: {
    status: '',
    message: '',
    rowsAffected: [],
  },
};

export const commissionReducer = createSlice({
  name: 'CommissionStatus',
  initialState,
  reducers: {
    clearCommissionStatus: (state: ICommission) => {
      state.status = 'idle';
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCommissionChainAllFees.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(postCommissionChainAllFees.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
      })
      .addCase(postCommissionChainAllFees.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      });
  },
});
export const { clearCommissionStatus } = commissionReducer.actions;
export default commissionReducer.reducer;
