import assigneesReducer from 'store/ducks/Assignees';
import commissionReducer from 'store/ducks/Commission';
import denyListReducer from 'store/ducks/DenyList';
import entryReducer from 'store/ducks/Entry';
import equalizerReducer from 'store/ducks/Equalizer';
import groupsReducer from 'store/ducks/Groups';
import hierarchyReducer from 'store/ducks/Hierarchy';
import notificationsReducer from 'store/ducks/Notifications';
import reportCommissionReducer from 'store/ducks/Reports/commission';
import dashboardReducer from 'store/ducks/Reports/dashboard';
import signerReducer from 'store/ducks/Signer';
import statusesReducer from 'store/ducks/Statuses';

import { combineReducers } from '@reduxjs/toolkit';

import userReducer from './ducks/User';
import validationReducer from './ducks/Validation';

const rootReducer = combineReducers({
  groups: groupsReducer,
  signer: signerReducer,
  assignees: assigneesReducer,
  entry: entryReducer,
  hierarchy: hierarchyReducer,
  commission: commissionReducer,
  statuses: statusesReducer,
  report: combineReducers({ commission: reportCommissionReducer, dashboard: dashboardReducer }),
  validate: validationReducer,
  notifications: notificationsReducer,
  user: userReducer,
  equalizer: equalizerReducer,
  denyList: denyListReducer,
});

export default rootReducer;
