/* eslint-disable no-param-reassign */
import { AxiosError } from 'axios';
import { reduxTemplate } from 'interfaces/redux';
import { passwordRecovery, recovery, usersResponse } from 'interfaces/Users';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getUsersList, postRecoverPassword, postSendEmailRecovery } from './service';

export const usersList = createAsyncThunk<usersResponse, void, { rejectValue: reduxTemplate }>(
  'users/list',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getUsersList();
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const sendEmailRecovery = createAsyncThunk(
  'user/sendEmailRecovery',
  async (body: recovery, { rejectWithValue }) => {
    try {
      const data = await postSendEmailRecovery(body);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const recoveryPassword = createAsyncThunk(
  'user/recoveryPassword',
  async (body: passwordRecovery, { rejectWithValue }) => {
    try {
      const data = await postRecoverPassword(body);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

interface IUser extends reduxTemplate {
  data: usersResponse;
}

const initialState: IUser = {
  status: 'idle',
  message: '',
  data: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
};

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser: (state) => {
      state.status = 'idle';
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(usersList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(usersList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.data = action.payload;
      })
      .addCase(usersList.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});
export const { clearUser } = user.actions;
export default user.reducer;
