import { Groups, CurrencyExchange, Dashboard, PriceCheck, ManageAccounts, Queue } from '@mui/icons-material';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export interface ISections {
  subheader: string;
  section: string;
  open: boolean;
  isVisible: boolean;
  label: string;
  href?: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  subsections?: {
    partners: {
      title: string;
      label: string;
      href?: string;
      icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
      };
      isVisible: boolean;
      open: boolean;
      itens: {
        title: string;
        label: string;
        href?: string;
        icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
          muiName: string;
        };
        isVisible: boolean;
        anchorEl?: null;
        menus?: {
          title: string;
          label: string;
          href?: string;
          isVisible: boolean;
          isNested?: boolean;
          nesteds?: {
            title: string;
            label: string;
            href: string;
          }[];
        }[];
      }[];
    }[];
  }[];
}

const sections: ISections[] = [
  {
    subheader: 'Dashboard',
    label: 'Dashboard',
    section: 'dashboard',
    href: '/dashboard',
    open: true,
    icon: Dashboard,
    isVisible: false,
  },
  {
    subheader: 'Cessonarios',
    label: 'Cessionários',
    section: 'assignees',
    href: '/assignees/list',
    open: true,
    icon: Groups,
    isVisible: false,
  },
  {
    subheader: 'Lançamentos',
    label: 'Lançamentos',
    section: 'transaction',
    href: '/entry/list',
    open: true,
    icon: PriceCheck,
    isVisible: false,
  },
  {
    subheader: 'Relatorios',
    label: 'Relatórios',
    section: 'distributorsAndShopkeepers2',
    href: 'none',
    open: true,
    isVisible: false,
    subsections: [
      {
        partners: [
          {
            title: 'Relatorios/Comissao',
            label: 'Comissão',
            href: '/report/commission',
            icon: CurrencyExchange,
            open: true,
            isVisible: false,
            itens: [],
          },
        ],
      },
    ],
  },
  {
    subheader: 'Gestão de Usuário',
    label: 'Gestão de Usuário',
    section: 'Gestão de Usuário',
    href: 'none',
    open: true,
    isVisible: false,
    subsections: [
      {
        partners: [
          {
            title: 'Usuarios',
            label: 'Usuário',
            href: '/UserSettings/Users',
            icon: ManageAccounts,
            open: false,
            isVisible: false,
            itens: [],
          },
        ],
      },
    ],
  },
  {
    subheader: 'Equalizador',
    label: 'Equalizador',
    section: 'Equalizador',
    open: true,
    isVisible: false,
    subsections: [
      {
        partners: [
          {
            title: 'Equalizador/Fila',
            label: 'Equalizar',
            href: '/Equalizer/Queue',
            icon: Queue,
            open: false,
            isVisible: false,
            itens: [],
          },
        ],
      },
    ],
  },
];

export default sections;
