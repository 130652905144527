/* eslint-disable import-helpers/order-imports */
import React, { useEffect } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import routes from 'routes';
import theme from 'styles/theme/dark';

import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import FakePage from 'pages/FakePage';
import { useAppSelector } from 'hooks/useAppStore';
import Layout from 'components/Layout';
import ErrorBoundary from 'components/ErrorBoundary';
import { CssBaseline } from '@mui/material';
import ptBR from 'dayjs/locale/pt-br';

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const signedRedux = useAppSelector((state) => {
    return state.signer.signed;
  });

  useEffect(() => {
    if (!signedRedux && !window.location.pathname.includes('recoverypassword')) {
      navigate('/signin');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedRedux, window.location.pathname]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ptBR.name}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary key={location.pathname}>
          <Routes>
            {routes.map(({ isPrivate, component: Component, path }) => {
              if (!isPrivate) {
                return <Route path={path} element={Component} key={path} />;
              }
              return false;
            })}
            <Route path="*" element={<FakePage />} />
            {signedRedux ? <Route path="/*" element={<Layout />} /> : <Route element={<Navigate to="/signin" />} />}
          </Routes>
        </ErrorBoundary>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
