/* eslint-disable import/prefer-default-export */

import { passwordRecovery, recovery, usersResponse } from 'interfaces/Users';
import api, { apiRecovery } from 'services';

export const getUsersList = async (): Promise<usersResponse> => {
  const response = await api.get<usersResponse>('/users/list');
  return response.data;
};

export const postSendEmailRecovery = async (payload: recovery) => {
  const response = await apiRecovery.post('/recovery', payload);
  return response.data;
};

export const postRecoverPassword = async (payload: passwordRecovery) => {
  const response = await apiRecovery.put('/password', payload);
  return response.data;
};
