import logo from 'assets/images/vileve_pay_azul_e_branco.png';
import ErrorBoundary from 'components/ErrorBoundary';
import FakePage from 'pages/FakePage';
import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import routes from 'routes';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, CssBaseline, Drawer, IconButton, Toolbar, useMediaQuery } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { useTheme, styled } from '@mui/material/styles';
import { Stack } from '@mui/system';

import MenuOptions from './MenuOptions';
import NavItem from './NavItem';

const drawerWidth = 300;

const Main = styled('main', {
  shouldForwardProp: (prop) => {
    return prop !== 'open';
  },
})<{
  open?: boolean;
}>(({ theme, open }) => {
  return {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  };
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => {
    return prop !== 'open';
  },
})<AppBarProps>(({ theme, open }) => {
  return {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  };
});

const DrawerHeader = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 0.5),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  };
});

const ResponsiveDrawer = () => {
  const theme = useTheme();
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const isDownLgScreen = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    if (isDownLgScreen) {
      setOpen(false);
    }
  }, [isDownLgScreen]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        elevation={0}
        sx={{
          borderRadius: 0,
          height: '80px',
          backgroundColor: 'rgb(14, 19, 32)',
          backdropFilter: 'blur(8px)',
          borderStyle: 'none',
          // boxShadow: 'rgba(34, 51, 84, 0.2) 0px 2px 8px -3px, rgba(34, 51, 84, 0.1) 0px 5px 22px -4px',
          color: (t) => {
            return t.palette.primary.light;
          },
        }}
      >
        <Toolbar>
          <IconButton
            color="default"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }), color: 'white' }}
          >
            <MenuIcon color="primary" />
          </IconButton>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ flexGrow: 1 }}>
            <MenuOptions />
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: 'rgb(28, 37, 54)',
            borderStyle: 'none',
            borderRadius: '0px',
            background: 'rgb(28, 37, 54)',
            boxShadow: '3px 8px 8px 4px rgba(0, 0, 0, 0.2)',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
            <Box padding={3}>
              <img alt="Vileve" src={logo} style={{ width: '100px', marginLeft: '15px' }} />
            </Box>
            <Box paddingTop={3}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </Box>
          </Stack>
        </DrawerHeader>

        <NavItem />
      </Drawer>
      <Main
        open={open}
        sx={{
          backgroundColor: 'rgb(14, 19, 32)',
          height: '100vh',
        }}
      >
        <DrawerHeader />
        <Box mb={2} />
        <ErrorBoundary key={location.pathname}>
          <Routes>
            {routes.map(({ isPrivate, path, component: Component }) => {
              if (isPrivate) {
                return <Route path={path} element={Component} key={path} />;
              }
              return false;
            })}
            <Route path="*" element={<FakePage />} />
          </Routes>
        </ErrorBoundary>
      </Main>
    </Box>
  );
};

export default ResponsiveDrawer;
