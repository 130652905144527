/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import { equalizerInfoResponse, postEqualizationPayload } from 'interfaces/Pipeline';
import api from 'services';

export const getEqualizationInfo = async (): Promise<equalizerInfoResponse> => {
  const response = await api.get<equalizerInfoResponse>('/pipeline/client/info');
  return response.data;
};

export const postEqualization = async (payload: postEqualizationPayload): Promise<equalizerInfoResponse> => {
  const response = await api.post<equalizerInfoResponse>('/pipeline/client/equalize', payload);
  return response.data;
};
