/* eslint-disable no-param-reassign */
import { AxiosError } from 'axios';
import {
  assignees,
  assigneesProfiles,
  ISalesSimulator,
  salesSimulatorResponse,
  listShopkeepersVileveQuery,
  shopkeepersVileve,
  distribuidorsResponse,
  assignResponsibleParams,
  assignResponsibleResponse,
  assigneesDynamicProfiles,
  listDistributorsVileveQuery,
  listAssigneesQuery,
} from 'interfaces/Assignee';
import { reduxTemplate } from 'interfaces/redux';

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import {
  getAssignees,
  getAssigneesList,
  getAssigneesProfiles,
  getShopkeepers,
  getSalesSimulator,
  getDistributors,
  patchAssignResponsible,
  getAssigneesDynamicProfiles,
} from './service';

export const assigneesList = createAsyncThunk<assignees, listAssigneesQuery, { rejectValue: reduxTemplate }>(
  'assignees/list?searchAdvanced',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getAssignees(params);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const distributorsList = createAsyncThunk<
  distribuidorsResponse,
  listDistributorsVileveQuery | undefined,
  { rejectValue: reduxTemplate }
>('assignees/distributors', async (searchAdvanced, { rejectWithValue }) => {
  try {
    const data = await getDistributors(searchAdvanced || {});
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

export const getAssigneeProfiles = createAsyncThunk<assigneesProfiles, void, { rejectValue: reduxTemplate }>(
  'assignees/getAssigneesProfiles',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getAssigneesProfiles();
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);
export const getAssigneeDynamicProfiles = createAsyncThunk<
  assigneesDynamicProfiles,
  string,
  { rejectValue: reduxTemplate }
>('assignees/assigneesDynamicProfiles', async (profile, { rejectWithValue }) => {
  try {
    const data = await getAssigneesDynamicProfiles(profile);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

export const updateAssigneesList = createAsyncThunk<assignees, void, { rejectValue: reduxTemplate }>(
  'assignees/updateList',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getAssigneesList();
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const shopkeepersList = createAsyncThunk<
  shopkeepersVileve,
  listShopkeepersVileveQuery | undefined,
  { rejectValue: reduxTemplate }
>('assignees/shopkeepers', async (payload, { rejectWithValue }) => {
  try {
    const data = await getShopkeepers(payload || {});
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

export const salesSimulator = createAsyncThunk<salesSimulatorResponse, ISalesSimulator, { rejectValue: reduxTemplate }>(
  'assignees/salesSimulator',
  async (salesSimulatorParams, { rejectWithValue }) => {
    try {
      const data = await getSalesSimulator(salesSimulatorParams);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const assignResponsible = createAsyncThunk<
  assignResponsibleResponse,
  assignResponsibleParams,
  { rejectValue: reduxTemplate }
>('assignees/assignResponsible', async (parms, { rejectWithValue }) => {
  try {
    const data = await patchAssignResponsible(parms);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

interface IAssignee extends reduxTemplate {
  assigneesContainer: assignees;
  updateList: assignees;
  profiles: assigneesProfiles;
  DynamicProfile: assigneesDynamicProfiles;
  shopkeepers: shopkeepersVileve;
  distributors: distribuidorsResponse;
}

const initialState: IAssignee = {
  status: 'idle',
  message: '',
  assigneesContainer: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  shopkeepers: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  distributors: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  updateList: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  profiles: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  DynamicProfile: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
};

export const assigneesReducer = createSlice({
  name: 'DistributorsStatus',
  initialState,
  reducers: {
    clearAssigneesStatus: (state) => {
      state.status = 'idle';
      state.message = '';
    },
    updateAssignee: (
      state,
      action: PayloadAction<{
        updatedCpf: string;
        update: Partial<assignees['document'][0]>;
      }>,
    ) => {
      const {
        payload: { update, updatedCpf },
      } = action;
      const index = state.assigneesContainer.document.findIndex((e) => {
        return e.cpf_cnpj === updatedCpf;
      });
      if (index === -1) {
        return;
      }
      const payloadKeys = Object.keys(update) as (keyof typeof update)[];
      for (const key of payloadKeys) {
        const value = update[key] as string | number;
        state.assigneesContainer.document[index][key] = value as never;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(assigneesList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(assigneesList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.assigneesContainer = action.payload;
      })
      .addCase(assigneesList.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(updateAssigneesList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateAssigneesList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.updateList = action.payload;
      })
      .addCase(updateAssigneesList.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(getAssigneeProfiles.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAssigneeProfiles.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.profiles = action.payload;
      })
      .addCase(getAssigneeProfiles.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(getAssigneeDynamicProfiles.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAssigneeDynamicProfiles.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.DynamicProfile = action.payload;
      })
      .addCase(getAssigneeDynamicProfiles.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(shopkeepersList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(shopkeepersList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.shopkeepers = action.payload;
      })
      .addCase(shopkeepersList.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(distributorsList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(distributorsList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.distributors = action.payload;
      })
      .addCase(distributorsList.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      });
  },
});
export const { clearAssigneesStatus, updateAssignee } = assigneesReducer.actions;
export default assigneesReducer.reducer;
