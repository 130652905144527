/* eslint-disable react/no-array-index-key */
import { useAppSelector } from 'hooks/useAppStore';
import sections, { ISections } from 'menus';
import NestedMenuItem from 'mui-nested-menu-item';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { ArrowRight, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItemText,
  Menu,
  MenuItem,
  ListItemSecondaryAction,
  ListItemIcon,
  Typography,
  ListItemButton,
} from '@mui/material';

import { style } from './styles';

interface IcurrentAnchorEl {
  partner: string;
  anchorEl: (EventTarget & Element) | null | undefined;
}

interface IopenControl {
  section: string;
  isOpen: boolean;
  partners: [{ partner: string; isOpen: boolean }];
}

const Sections = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const permissions = useAppSelector((state) => {
    return state.groups.groupsAndPermission.data;
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCurrentSections] = useState<ISections[]>(sections);
  const [currentAnchorEl, setCurrentAnchorEl] = useState<IcurrentAnchorEl[]>([]);
  const [open] = useState(true);

  useEffect(() => {
    const anchorEl: IcurrentAnchorEl[] = [];
    const openControl: IopenControl[] = [];
    sections.forEach((sectionArray) => {
      sectionArray.subsections?.forEach((subsection) => {
        subsection.partners.forEach((partner) => {
          openControl.push({
            section: sectionArray.section,
            isOpen: sectionArray.open,
            partners: [{ partner: partner.title, isOpen: open }],
          });

          partner.itens.forEach((item) => {
            anchorEl.push({ partner: item.title, anchorEl: item.anchorEl });
          });
        });
      });
    });
    setCurrentAnchorEl([...anchorEl]);
  }, [open]);

  useEffect(() => {
    const newArray = [...sections];
    if (permissions) {
      const permissionSection = permissions.filter((section) => {
        if (section.tipo === 'SECTION') {
          return section;
        }

        return false;
      });

      const permissionsSubSection = permissions.filter((subSection) => {
        if (subSection.tipo === 'SUBSECTION') {
          return subSection;
        }
        return false;
      });

      const permissionsItem = permissions.filter((item) => {
        if (item.tipo === 'ITEM') {
          return item;
        }
        return false;
      });
      const permissionsMenu = permissions.filter((menu) => {
        if (menu.tipo === 'MENU') {
          return menu;
        }
        return false;
      });

      permissionSection.forEach((section, index) => {
        if (permissionSection[index]) {
          const currentSection = sections.findIndex((findSectionIndex) => {
            return findSectionIndex.subheader === section.funcionalidade;
          });

          if (newArray[currentSection]) {
            newArray[currentSection].isVisible = section.visualizar;
          }
          setCurrentSections([...newArray]);
        } else {
          newArray[index].isVisible = false;
        }
      });

      sections.forEach((section, indexSection) => {
        const existSubsection = section.subsections;
        if (existSubsection) {
          const existNewArray = newArray[indexSection];
          let existNewArraySubsection: ISections['subsections'];

          if (existNewArray) {
            existNewArraySubsection = existNewArray.subsections;
            if (existNewArraySubsection) {
              // eslint-disable-next-line operator-linebreak
              existNewArraySubsection[0].partners.forEach((partner, indexPartner) => {
                const indexPermissionSubSection = permissionsSubSection.findIndex((permissionSubSection) => {
                  return permissionSubSection.funcionalidade === partner.title;
                });
                if (existNewArraySubsection) {
                  if (indexPermissionSubSection > -1) {
                    // eslint-disable-next-line operator-linebreak
                    existNewArraySubsection[0].partners[indexPartner].isVisible =
                      permissionsSubSection[indexPermissionSubSection].visualizar;

                    partner.itens.forEach((item, indexItem) => {
                      const indexPermissionItem = permissionsItem.findIndex((permissionItem) => {
                        return permissionItem.funcionalidade === item.title;
                      });

                      if (indexPermissionItem > -1 && existNewArraySubsection) {
                        // eslint-disable-next-line operator-linebreak
                        existNewArraySubsection[0].partners[indexPartner].itens[indexItem].isVisible =
                          permissionsItem[indexPermissionItem].visualizar;

                        item.menus?.forEach((menu, indexMenu) => {
                          const indexPermissionMenu = permissionsMenu.findIndex((permissionMenu) => {
                            return permissionMenu.funcionalidade === menu.title;
                          });

                          if (indexPermissionMenu > -1 && existNewArraySubsection) {
                            const existMenu = existNewArraySubsection[0].partners[indexPartner].itens[indexItem].menus;
                            if (existMenu) {
                              existMenu[indexMenu].isVisible = permissionsMenu[indexPermissionMenu].visualizar;
                            }
                          }
                        });
                      } else if (existNewArraySubsection) {
                        existNewArraySubsection[0].partners[indexPartner].itens[indexItem].isVisible = false;
                      }
                    });
                  } else {
                    existNewArraySubsection[0].partners[indexPartner].isVisible = false;
                  }
                }
              });
            }
          }
        }
      });
    }
  }, [permissions]);

  const handleClose = (): void => {
    const newArray: IcurrentAnchorEl[] = [];
    sections.forEach((sectionArray) => {
      sectionArray.subsections?.forEach((subsection) => {
        subsection.partners.forEach((partner) => {
          partner.itens.forEach((item) => {
            newArray.push({ partner: item.title, anchorEl: null });
          });
        });
      });
    });
    setCurrentAnchorEl([...newArray]);
  };

  const showMenuDinamyc = (currentPartner: string, event: React.MouseEvent): void => {
    setCurrentAnchorEl(
      currentAnchorEl.map((anchor) => {
        if (anchor.partner === currentPartner) {
          return {
            partner: anchor.partner,
            anchorEl: event.currentTarget,
          };
        }
        return {
          partner: anchor.partner,
          anchorEl: null,
        };
      }),
    );
  };

  const handleSections = (currentSectionContext: string = '') => {
    const currentSection = sections.findIndex((findSectionIndex) => {
      return findSectionIndex.section === currentSectionContext;
    });

    const newArray = [...sections];
    newArray[currentSection].open = !sections[currentSection].open;
    setCurrentSections([...newArray]);
  };

  const handleSubsections = (currentSubsectionContext: string, currentSectionContext: string = '') => {
    const currentSection = sections.findIndex((findSectionIndex) => {
      return findSectionIndex.section === currentSectionContext;
    });

    const existSection = sections[currentSection];
    let existSubsection: ISections['subsections'];
    if (existSection) {
      existSubsection = existSection.subsections;
      if (existSubsection) {
        const currentPartner = existSubsection[0].partners.findIndex((partner) => {
          return partner.title === currentSubsectionContext;
        });

        const newArray = [...sections];
        const existNewArray = newArray[currentSection];
        let existNewArraySubsection: ISections['subsections'];

        if (existNewArray) {
          existNewArraySubsection = existNewArray.subsections;
          if (existNewArraySubsection) {
            // eslint-disable-next-line operator-linebreak
            existNewArraySubsection[0].partners[currentPartner].open =
              !existNewArraySubsection[0].partners[currentPartner].open;
            setCurrentSections([...newArray]);
          }
        }
      }
    }
  };

  const findHeref = (href: string) => {
    const splitedRef = href.split('/')[1];
    return location.pathname.includes(splitedRef);
  };

  return (
    <>
      {sections.map((section) => {
        const IconSection = section.icon;
        return (
          section.isVisible && (
            <List component="nav" aria-labelledby="nested-list-subheader" key={`${section}`}>
              <ListItemButton
                component={Link}
                to={section.href ? section.href : '/'}
                key={section.subheader}
                sx={{
                  ...style.listButton,
                  pointerEvents: section.href !== 'none' ? 'auto' : 'none',
                }}
                selected={findHeref(section.href || '')}
                onClick={() => {
                  if (!section.href) {
                    handleSections(section.section);
                  } else {
                    navigate(section.href);
                  }
                }}
              >
                {IconSection && (
                  <ListItemIcon>
                    <IconSection color="primary" fontSize="medium" />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Typography color="primary" sx={{ fontSize: '1.2rem', lineHeight: '1.75', fontWeight: 500 }}>
                      {section.label}
                    </Typography>
                  }
                />
                {
                  // eslint-disable-next-line operator-linebreak
                  section.subsections &&
                    (section.open ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />)
                }
              </ListItemButton>
              {section.subsections && (
                <Collapse in={section.open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding key={`colapse-section=${section}`}>
                    {section.subsections?.map((subsection) => {
                      return subsection.partners.map((partner, indexPartner) => {
                        const Icon = partner.icon;
                        return (
                          partner.isVisible && (
                            <>
                              <ListItemButton
                                component={Link}
                                to={partner.href ? partner.href : '/'}
                                selected={findHeref(partner.href || '')}
                                sx={{
                                  pl: 4,
                                  ...style.listButton,
                                }}
                                key={`partner-${indexPartner}`}
                                onClick={() => {
                                  handleSubsections(partner.title, section.section);
                                }}
                              >
                                <ListItemIcon>
                                  <Icon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    // eslint-disable-next-line react/jsx-wrap-multilines
                                    <Typography
                                      sx={{ fontSize: '1.09rem', lineHeight: '1.75', fontWeight: 500 }}
                                      color="primary"
                                    >
                                      {partner.label}
                                    </Typography>
                                  }
                                />
                                {!partner.href &&
                                  (partner.open ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />)}
                              </ListItemButton>
                              {partner.itens.map((item) => {
                                const currentItemIndex = currentAnchorEl.findIndex((findIndexAnchorEl) => {
                                  return findIndexAnchorEl.partner === item.title;
                                });
                                const IconPartner = item.icon;
                                let existMenu: boolean = false;
                                if (item.menus) {
                                  if (item.menus.length > 0) {
                                    existMenu = !!item.menus.find((menu) => {
                                      return menu.isVisible === true;
                                    });
                                  }
                                }
                                return (
                                  // eslint-disable-next-line operator-linebreak
                                  item.isVisible && (
                                    <Collapse
                                      in={partner.open}
                                      timeout="auto"
                                      unmountOnExit
                                      key={`indexPartner-${item.title}`}
                                    >
                                      <ListItemButton
                                        component={Link}
                                        to={item.href ? item.href : '/'}
                                        selected={findHeref(item.href || '')}
                                        key={item.title}
                                        sx={{ pl: 6 }}
                                      >
                                        <ListItemIcon>
                                          <IconPartner color="primary" />
                                        </ListItemIcon>

                                        <ListItemText
                                          primary={
                                            // eslint-disable-next-line react/jsx-wrap-multilines
                                            <Typography sx={{ fontSize: '1.1rem', lineHeight: '1.75' }} color="primary">
                                              {item.label}
                                            </Typography>
                                          }
                                          onClick={(event: React.MouseEvent) => {
                                            if (!item.href) {
                                              showMenuDinamyc(item.title, event);
                                            }
                                          }}
                                        />

                                        {existMenu && (
                                          <ListItemSecondaryAction>
                                            <Menu
                                              id={`lock-menu${item.title}`}
                                              anchorEl={currentAnchorEl[currentItemIndex]?.anchorEl}
                                              keepMounted
                                              open={Boolean(currentAnchorEl[currentItemIndex]?.anchorEl)}
                                              onClose={handleClose}
                                            >
                                              {item.menus?.map((menu, indexMenu) => {
                                                if (menu.isNested && !menu.href) {
                                                  return (
                                                    menu.isVisible && (
                                                      <NestedMenuItem
                                                        label={menu.title}
                                                        parentMenuOpen={Boolean(
                                                          currentAnchorEl[currentItemIndex]?.anchorEl,
                                                        )}
                                                        key={`nested-menu-${menu.title}`}
                                                      >
                                                        {menu.nesteds?.map((nested) => {
                                                          return (
                                                            <MenuItem
                                                              key={nested.title}
                                                              component={Link}
                                                              to={nested.href}
                                                            >
                                                              <Typography
                                                                sx={{ fontSize: '1.2rem', lineHeight: '1.75' }}
                                                                color="primary"
                                                              >
                                                                {nested.label}
                                                              </Typography>
                                                            </MenuItem>
                                                          );
                                                        })}
                                                      </NestedMenuItem>
                                                    )
                                                  );
                                                }
                                                return (
                                                  menu.isVisible && (
                                                    <MenuItem
                                                      key={`menu-${indexMenu}`}
                                                      component={Link}
                                                      onClick={handleClose}
                                                      to={menu.href as string}
                                                    >
                                                      <Typography
                                                        sx={{ fontSize: '1.2rem', lineHeight: '1.75' }}
                                                        color="primary"
                                                      >
                                                        {menu.label}
                                                      </Typography>
                                                    </MenuItem>
                                                  )
                                                );
                                              })}
                                            </Menu>

                                            {item.menus && (
                                              <ArrowRight
                                                color="primary"
                                                id={`arrow-${currentItemIndex}`}
                                                onMouseMove={(event: React.MouseEvent) => {
                                                  showMenuDinamyc(item.title, event);
                                                }}
                                                onClick={(event: React.MouseEvent) => {
                                                  showMenuDinamyc(item.title, event);
                                                }}
                                              />
                                            )}
                                          </ListItemSecondaryAction>
                                        )}
                                      </ListItemButton>
                                    </Collapse>
                                  )
                                );
                              })}
                            </>
                          )
                        );
                      });
                    })}
                  </List>
                </Collapse>
              )}
            </List>
          )
        );
      })}
    </>
  );
};

export default Sections;
