/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import {
  assignees,
  assigneesProfiles,
  ISalesSimulator,
  salesSimulatorResponse,
  listShopkeepersVileveQuery,
  shopkeepersVileve,
  distribuidorsResponse,
  assignResponsibleParams,
  assignResponsibleResponse,
  assigneesDynamicProfiles,
  listDistributorsVileveQuery,
  listAssigneesQuery,
} from 'interfaces/Assignee';
import api from 'services';

export const getAssignees = async (params: listAssigneesQuery): Promise<assignees> => {
  const response = await api.get<assignees>('/assignees/list', { params });
  return response.data;
};

export const getAssigneesList = async (): Promise<assignees> => {
  const response = await api.get<assignees>('/assignees/updateList');
  return response.data;
};

export const getAssigneesProfiles = async (): Promise<assigneesProfiles> => {
  const response = await api.get<assigneesProfiles>('/assignees/assigneesProfiles');
  return response.data;
};
export const getAssigneesDynamicProfiles = async (profile: string): Promise<assigneesDynamicProfiles> => {
  const response = await api.get<assigneesDynamicProfiles>(`/assignees/assigneesDynamicProfiles?profile=${profile}`);
  return response.data;
};

export const getShopkeepers = async (params: listShopkeepersVileveQuery): Promise<shopkeepersVileve> => {
  const response = await api.get<shopkeepersVileve>('/assignees/shopkeepers', {
    params,
  });
  return response.data;
};

export const getDistributors = async (params: listDistributorsVileveQuery): Promise<distribuidorsResponse> => {
  const response = await api.get<distribuidorsResponse>('/assignees/distributors', {
    params,
  });
  return response.data;
};

export const getSalesSimulator = async (params: ISalesSimulator): Promise<salesSimulatorResponse> => {
  const response = await api.get<salesSimulatorResponse>('/simulator', { params });
  return response.data;
};

export const patchAssignResponsible = async (params: assignResponsibleParams): Promise<assignResponsibleResponse> => {
  const response = await api.patch<assignResponsibleResponse>('/assignees/assignResponsible', {}, { params });
  return response.data;
};
