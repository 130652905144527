/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React, { ReactElement } from 'react';

import { Menu, MenuItem } from '@mui/material';

type IMenuItem = typeof MenuItem;

interface Props {
  children: ReactElement<IMenuItem> | Array<ReactElement<IMenuItem>>;
  anchorEl: null | HTMLElement;
  open: boolean;
  onClose: () => void;
  styleDialog?: boolean;
}

const CustomMenu = ({ children, anchorEl, open, onClose, styleDialog = true }: Props) => {
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu-custom"
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          padding: '7px',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': () => {
            return styleDialog
              ? {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'rgb(5, 30, 52)',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                }
              : {};
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {children}
    </Menu>
  );
};

export default CustomMenu;

CustomMenu.defaultProps = { styleDialog: true };
