/* eslint-disable import/prefer-default-export */
import { defaultResponse } from 'interfaces/defaultResponse';
import api from 'services';

const getValidate = async (): Promise<defaultResponse> => {
  const response = await api.get<defaultResponse>('/validation', {});
  return response.data;
};

export { getValidate };
