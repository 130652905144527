/* eslint-disable import/prefer-default-export */

import { statusResponse, statusRequestParams } from 'interfaces/Status';
import api from 'services';

export const getAssigneeStatuses = async (params: statusRequestParams): Promise<statusResponse> => {
  const response = await api.get<statusResponse>('/status', {
    params,
  });
  return response.data;
};
