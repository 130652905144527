/* eslint-disable no-param-reassign */
import { AxiosError } from 'axios';
import { reduxTemplate } from 'interfaces/redux';
import { statusRequestParams, statusResponse } from 'interfaces/Status';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getAssigneeStatuses } from './service';

interface IStatusesState extends reduxTemplate {
  statuses: statusResponse;
}

export const getStatuses = createAsyncThunk<statusResponse, statusRequestParams, { rejectValue: reduxTemplate }>(
  'statuses/getStatuses',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getAssigneeStatuses(params);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

const defaultStatusesState = {
  message: '',
  status: '',
  document: [],
  rowsAffected: [],
};

const initialState: IStatusesState = {
  status: 'idle',
  message: '',
  statuses: defaultStatusesState,
};

const statusesReducer = createSlice({
  name: 'status',
  initialState,
  reducers: {
    clearStatuses: (state: IStatusesState) => {
      state.status = 'idle';
      state.message = '';
      state.statuses = defaultStatusesState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getStatuses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getStatuses.fulfilled, (state, action) => {
        state.status = 'completed';
        state.statuses = action.payload;
      })
      .addCase(getStatuses.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { clearStatuses } = statusesReducer.actions;
export default statusesReducer.reducer;
