/* eslint-disable no-param-reassign */
import { AxiosError } from 'axios';
import { assignees, treeHierarchyDocument, allChainDocument, queryAllChain } from 'interfaces/Assignee';
import { updateResponseDb } from 'interfaces/defaultResponse';
import {
  countHierarchiesParams,
  paramsQueryAdvanced,
  payloadInsertHierarchy,
  responseInsertHierarchyResponse,
  resposeHierarchyCount,
  unlinkHierarchyParms,
} from 'interfaces/Hierarchy';
import { reduxTemplate } from 'interfaces/redux';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getTreeHierarchy,
  getValidHierarchyList,
  putValidHierarchyList,
  getAllChain,
  putUnlinkHierarchy,
  getCountHierarchies,
} from './service';

export const treeHierarchy = createAsyncThunk<treeHierarchyDocument, queryAllChain, { rejectValue: reduxTemplate }>(
  'hierarchy/treeHierarchy',
  async (payload, { rejectWithValue }) => {
    try {
      const data: treeHierarchyDocument = await getTreeHierarchy(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const allHierarchyChain = createAsyncThunk<allChainDocument, queryAllChain, { rejectValue: reduxTemplate }>(
  'hierarchy/allHierarchyChain',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await getAllChain(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const searchHierarchyList = createAsyncThunk<assignees, paramsQueryAdvanced, { rejectValue: reduxTemplate }>(
  'hierarchy/validList?params',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getValidHierarchyList(params);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const setProfile = createAsyncThunk<assignees, payloadInsertHierarchy, { rejectValue: reduxTemplate }>(
  'hierarchy/setProfileHierarchy?params',
  async (params, { rejectWithValue }) => {
    try {
      const data: assignees = await putValidHierarchyList(params);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const countHierarchies = createAsyncThunk<
  resposeHierarchyCount,
  countHierarchiesParams,
  { rejectValue: reduxTemplate }
>('hierarchy/countHierarchies', async (params, { rejectWithValue }) => {
  try {
    const data = await getCountHierarchies(params);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

export const setUnlinkHierarchy = createAsyncThunk<
  updateResponseDb,
  unlinkHierarchyParms,
  { rejectValue: reduxTemplate }
>('hierarchy/unlinkProfileHierarchy', async (params, { rejectWithValue }) => {
  try {
    const data: updateResponseDb = await putUnlinkHierarchy(params);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

interface IHierarchy extends reduxTemplate {
  allHierarchyChain: allChainDocument;
  treeHierarchyList: treeHierarchyDocument;
  searchValidHierarchyList: assignees;
  firstSetProfile: responseInsertHierarchyResponse;
  hierarchyCount: resposeHierarchyCount;
}

const initialState: IHierarchy = {
  status: 'idle',
  message: '',
  allHierarchyChain: {
    status: '',
    message: '',
    data: [],
    rowsAffected: [],
  },
  treeHierarchyList: {
    status: '',
    message: '',
    data: [],
    rowsAffected: [],
  },
  searchValidHierarchyList: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  firstSetProfile: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  hierarchyCount: {
    status: '',
    message: '',
    data: { totalEcs: 0, totalLowHierarchies: 0 },
    rowsAffected: [],
  },
};

export const hierarchyReducer = createSlice({
  name: 'HierarchyStatus',
  initialState,
  reducers: {
    clearHierarchyStatus: (state: IHierarchy) => {
      state.status = 'idle';
      state.message = '';
    },
    clearHierarchyCount: (state: IHierarchy) => {
      state.status = 'idle';
      state.message = '';
      state.hierarchyCount = {
        status: '',
        message: '',
        data: { totalEcs: 0, totalLowHierarchies: 0 },
        rowsAffected: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(treeHierarchy.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(treeHierarchy.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.treeHierarchyList = action.payload;
      })
      .addCase(treeHierarchy.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(searchHierarchyList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(searchHierarchyList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.searchValidHierarchyList = action.payload;
      })
      .addCase(searchHierarchyList.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(setProfile.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(setProfile.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
      })
      .addCase(setProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(setUnlinkHierarchy.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(setUnlinkHierarchy.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
      })
      .addCase(setUnlinkHierarchy.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(countHierarchies.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(countHierarchies.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload?.message;
        state.hierarchyCount = action.payload;
      })
      .addCase(countHierarchies.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      });
  },
});
export const { clearHierarchyStatus, clearHierarchyCount } = hierarchyReducer.actions;
export default hierarchyReducer.reducer;
