/* eslint-disable no-param-reassign */
import { AxiosError } from 'axios';
import { notifications } from 'interfaces/Notifications';
import { reduxTemplate } from 'interfaces/redux';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getNotifications } from './service';

export const notificationsList = createAsyncThunk<notifications, void, { rejectValue: reduxTemplate }>(
  'notifications',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getNotifications();
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

interface INotifications extends reduxTemplate {
  list: notifications;
}

const initialState: INotifications = {
  status: 'idle',
  message: '',
  list: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
};

export const notificationsReducer = createSlice({
  name: 'NotificationsStatus',
  initialState,
  reducers: {
    clearNotificationsStatus: (state) => {
      state.status = 'idle';
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(notificationsList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(notificationsList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.list = action.payload;
      })
      .addCase(notificationsList.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      });
  },
});
export const { clearNotificationsStatus } = notificationsReducer.actions;
export default notificationsReducer.reducer;
