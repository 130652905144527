/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import { bodyChainCommission } from 'interfaces/Commission';
import { defaultResponse } from 'interfaces/defaultResponse';
import api from 'services';

export const postCommissionChain = async (params?: bodyChainCommission[]): Promise<defaultResponse> => {
  const response = await api.post<defaultResponse>('/commission/treeHierarchyFees', params);
  return response.data;
};
