import paletteColors from 'styles/theme/colors';
import typography from 'styles/theme/typography';

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography,
  components: {
    MuiTypography: {
      styleOverrides: { root: { color: paletteColors.primaryLigth } },
    },
    MuiIconButton: {
      styleOverrides: { root: { color: paletteColors.primaryLigth } },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: { backgroundColor: '#FFFFFFCC', color: 'rgb(14, 19, 32)' },
        root: { borderRadius: '20px', fontSize: '0.875rem', fontWeight: 600, padding: '8px 20px' },
      },
    },
    MuiListSubheader: {
      styleOverrides: { root: { backgroundColor: paletteColors.listSubHeader } },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:hover': { borderRadius: 15 },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': { borderRadius: 15 },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          backgroundColor: 'rgb(17, 25, 39)',
          backgroundImage: 'none',
          // border: `1px solid ${paletteColors.primary}`,
        },
      },
    },
    MuiIcon: { styleOverrides: { colorPrimary: paletteColors.icon } },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          borderBottom: '0px',
        },
      },
    },
  },
  palette: {
    primary: { main: paletteColors.primaryLigth },
    secondary: { main: paletteColors.primary },
    background: { paper: paletteColors.background, default: paletteColors.primary },
    mode: 'dark',
  },
});

export default theme;
