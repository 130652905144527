/* eslint-disable import/prefer-default-export */
import {
  reportedChainResponse,
  ChainReportParams,
  transactionsReportParams,
  transactionsReportResponse,
  commissioningByDistResponse,
  commissioningByDistParams,
  dcTransactionsReportParms,
  distributorsGeneralReportParms,
} from 'interfaces/Reports';
import api from 'services';
import { linkToDownloadFileCsv } from 'utils/html';

const path = 'metrics';

export const getMetricsChainReport = async (params: ChainReportParams): Promise<reportedChainResponse> => {
  const response = await api.get<reportedChainResponse>(`${path}/ChainReport`, {
    params,
  });
  return response.data;
};

export const getTransactionsReport = async (params: transactionsReportParams): Promise<void> => {
  const response = await api.get<transactionsReportResponse>(`${path}/transactionsReport`, {
    params,
  });

  linkToDownloadFileCsv(
    response.data,
    `relatorio_comissao_detalhada_por_transacao_${params.EC}_${params.startDate}_${params.endDate}`,
  );
};

export const getChainCommissioningByDist = async (
  params: commissioningByDistParams,
): Promise<commissioningByDistResponse> => {
  const response = await api.get<commissioningByDistResponse>(`${path}/chainCommissioningByDist`, {
    params,
  });
  return response.data;
};

export const getDCtransactionsReport = async (params: dcTransactionsReportParms) => {
  const response = await api.get(`${path}/DCtransactionsReport`, {
    params,
    timeout: Infinity,
    responseType: 'blob',
  });

  linkToDownloadFileCsv(response.data, `relatorio_mensal_detalhado_${params.month}_${params.DC}`);
  return {};
};

export const getDistributorsGeneralReport = async (params: distributorsGeneralReportParms) => {
  const response = await api.get(`${path}/distributorsGeneralReport`, { params });

  linkToDownloadFileCsv(response.data, `relatorio_mensal_geral_${params.month}`);
};
