/* eslint-disable import/prefer-default-export */
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export const style: { typography: SxProps<Theme>; listButton: SxProps<Theme> } = {
  typography: {
    fontFamily:
      // eslint-disable-next-line max-len
      'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    whiteSpace: 'nowrap',
  },
  listButton: {
    margin: '3px 7px 3px 7px',
    '&.Mui-selected': {
      borderRadius: '15px',
    },
  },
};
