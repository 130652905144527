/* eslint-disable no-param-reassign */
import { AxiosError } from 'axios';
import { entry } from 'interfaces/Entry';
import { reduxTemplate } from 'interfaces/redux';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getEntryList } from './service';

export const entryList = createAsyncThunk<entry, void, { rejectValue: reduxTemplate }>(
  'transaction/list',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getEntryList();
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

interface IEntry extends reduxTemplate {
  list: entry;
}

const initialState: IEntry = {
  status: 'idle',
  message: '',
  list: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
};

export const entryReducer = createSlice({
  name: 'EntryStatus',
  initialState,
  reducers: {
    clearEntryStatus: (state) => {
      state.status = 'idle';
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(entryList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(entryList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.list = action.payload;
      })
      .addCase(entryList.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      });
  },
});
export const { clearEntryStatus } = entryReducer.actions;
export default entryReducer.reducer;
