/* eslint-disable no-param-reassign */
import { AxiosError } from 'axios';
import { denyListEcResponse, postDenyListBody } from 'interfaces/DenyList';
import { reduxTemplate } from 'interfaces/redux';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  deleteDenyList,
  getDenyList,
  postDenyList,
} from './service';

export const createDenyList = createAsyncThunk<
  denyListEcResponse,
  postDenyListBody,
  { rejectValue: reduxTemplate }
>('denyList/createDenyList', async (body, { rejectWithValue }) => {
  try {
    const data = await postDenyList(body);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

export const removeDenyList = createAsyncThunk<
  denyListEcResponse,
  postDenyListBody,
  { rejectValue: reduxTemplate }
>('denyList/removeDenyList', async (params, { rejectWithValue }) => {
  try {
    const data = await deleteDenyList(params);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

export const fetchDenyList = createAsyncThunk<
  denyListEcResponse,
  Partial<postDenyListBody> | undefined,
  { rejectValue: reduxTemplate }
>('denyList/fetchDenyList', async (params, { rejectWithValue }) => {
  try {
    const data = await getDenyList(params);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

interface IDenyList extends reduxTemplate {
  denied: denyListEcResponse;
}

const initialState: IDenyList = {
  status: 'idle',
  message: '',
  denied: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
};

export const denyListReducer = createSlice({
  name: 'DistributorsStatus',
  initialState,
  reducers: {
    clearDenyList: (state) => {
      state.status = 'idle';
      state.message = '';
      state.denied = {
        status: '',
        message: '',
        document: [],
        rowsAffected: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDenyList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(createDenyList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.denied.document.push(action.payload.document[0]);
        state.denied = action.payload;
      })
      .addCase(createDenyList.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(fetchDenyList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchDenyList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.denied = action.payload;
      })
      .addCase(fetchDenyList.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(removeDenyList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(removeDenyList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        const { cpfCnpj } = action.meta.arg;
        const index = state.denied.document.findIndex((deny) => { return deny.cpf_cnpj === cpfCnpj; });
        if (index !== -1) {
          state.denied.document.splice(index, 1);
        }
        state.denied = action.payload;
      })
      .addCase(removeDenyList.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      });
  },
});
export const { clearDenyList } = denyListReducer.actions;
export default denyListReducer.reducer;
