/* eslint-disable no-param-reassign */
import { AxiosError } from 'axios';
import { defaultResponse } from 'interfaces/defaultResponse';
import { reduxTemplate } from 'interfaces/redux';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getValidate } from './service';

interface IValidateState extends reduxTemplate {
  validate: defaultResponse;
}

const defaultValidateState = {
  message: '',
  status: '',
  rowsAffected: [],
};

const initialState: IValidateState = {
  status: 'idle',
  message: '',
  validate: defaultValidateState,
};

export const getValidation = createAsyncThunk<defaultResponse>(
  'validation/getValidation',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getValidate();
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

const validateReducer = createSlice({
  name: 'validate',
  initialState,
  reducers: {
    clearValidation: (state: IValidateState) => {
      state.status = 'idle';
      state.message = '';
      state.validate = defaultValidateState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getValidation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getValidation.fulfilled, (state, action) => {
        state.status = 'completed';
        state.validate = action.payload;
      })
      .addCase(getValidation.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { clearValidation } = validateReducer.actions;
export default validateReducer.reducer;
