/* eslint-disable max-len */
/* eslint-disable no-param-reassign */

import { AxiosError } from 'axios';
import { reduxTemplate } from 'interfaces/redux';
import { signer, signerResponse, recovery, passwordRecovery } from 'interfaces/Signer';

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { postSignin, recoverPassword, sendEmailRecover } from './service';

export const signin = createAsyncThunk<signerResponse, signer, { rejectValue: reduxTemplate }>(
  'signer/signin',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await postSignin(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

interface ISignerReducer extends reduxTemplate {
  statusMessage: string;
  signed: boolean;
  signin: signerResponse;
}

const initialState: ISignerReducer = {
  status: 'idle',
  signed: false,
  message: '',
  signin: {
    status: '',
    message: '',
    user: '',
    token: '',
    currentRefreshToken: '',
  },
  statusMessage: '',
};

export const sendEmailRecovery = createAsyncThunk(
  'signer/sendEmailRecovery',
  async (body: recovery, { rejectWithValue }) => {
    try {
      const data = await sendEmailRecover(body);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const signerReducer = createSlice({
  name: 'signer',
  initialState,
  reducers: {
    clearSignin: (state: ISignerReducer) => {
      state.status = 'idle';
      state.signed = false;
      state.signin = {
        status: '',
        message: '',
        token: '',
        currentRefreshToken: '',
        user: '',
      };
    },
    setRefreshToken: (state: ISignerReducer, action: PayloadAction<{ token: string }>) => {
      state.status = 'idle';
      state.signed = true;
      state.signin.token = action.payload.token;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signin.fulfilled, (state, action) => {
        state.status = 'completed';
        state.signed = true;
        state.signin = action.payload;
      })
      .addCase(signin.rejected, (state) => {
        state.status = 'failed';
        state.signed = false;
      });
  },
});

export const recoveryPassword = createAsyncThunk(
  'signer/recoveryPassword',
  async (body: passwordRecovery, { rejectWithValue }) => {
    try {
      const data = await recoverPassword(body);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const { clearSignin, setRefreshToken } = signerReducer.actions;
export default signerReducer.reducer;
