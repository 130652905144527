import { denyListEcResponse, postDenyListBody } from 'interfaces/DenyList';
import api from 'services';

export const postDenyList = async (body: postDenyListBody): Promise<denyListEcResponse> => {
  const response = await api.post<denyListEcResponse>('/denyList', body);
  return response.data;
};

export const deleteDenyList = async (data: postDenyListBody): Promise<denyListEcResponse> => {
  const response = await api.delete<denyListEcResponse>(`/denyList/${data.cpfCnpj}`);
  return response.data;
};

export const getDenyList = async (params?: Partial<postDenyListBody>): Promise<denyListEcResponse> => {
  const response = await api.get<denyListEcResponse>('/denyList', { params });
  return response.data;
};
